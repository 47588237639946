import { Paths } from "../paths"

export default function getCurrentTab(path) {
  if (path === Paths.Home) {
    return "home"
  } else {
    switch (true) {
      case path.startsWith(Paths.products):
        return "products"
      case path.startsWith(Paths.content):
        return "content"
      case path.startsWith(Paths.contact):
        return "contact"
      default:
        return 'me'
    }
  }
}
