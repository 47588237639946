import { Link } from "gatsby"
import React from "react"
import { Paths } from "../paths"
import getCurrentTab from "../utils/getCurrentTab"
import { rhythm, scale } from '../utils/typography'

const NavigationBar = ({ currentPath }) => {
  const currentTab = getCurrentTab(currentPath)
  return (
    <div className="navbar-container" style={{marginBottom: rhythm(1.5)}}>
      {Object.entries(Paths).map(entry => (
        <Link
          style={{
            ...scale(0.2),
            marginRight: 16,
            boxShadow: "none",
            textDecoration: entry[0] === currentTab ? "underline" : "none",
            fontWeight: "bold",
            fontFamily: 'Montserrat, sans-serif',
            color: "inherit",
          }}
          to={entry[1]}
        >
          {entry[0]}
        </Link>
      ))}
    </div>
  )
}

export default NavigationBar
