import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Paths } from "../paths"
import { rhythm } from "../utils/typography"
import NavigationBar from "./navbar"

const Layout = props => {
  const { location, title, children } = props

  return (
    <div className="layout-wrapper">
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>
          <div
            className="layout-header-container"
            style={{ marginBottom: rhythm(1 / 2) }}
          >
            <h2
              style={{
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              {title}
            </h2>
          </div>
        </header>
        <NavigationBar currentPath={location.pathname} />
        <main>{children}</main>
      </div>
      <footer className="layout-footer">
        © {new Date().getFullYear()}, Jakub Adamczyk
      </footer>
    </div>
  )
}

Layout.fullBlogPath = `${__PATH_PREFIX__}${Paths.Blog}`

export default Layout
